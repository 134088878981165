<template>
  <div class="d-flex flex-column">
    <h1 class="text-h5 font-weight-bold mb-2">
      Autoavaliação Concluída com Sucesso.
    </h1>
    <div class="d-flex flex-column align-center justify-center my-3">
      <v-img :src="require('@/assets/images/3d-characters/illustration-john-2.png')" alt="imagem de finalizacao avaliacao"
        contain height="12em" width="12em" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EvaluationFinish",
};
</script>

<style></style>
